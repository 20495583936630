import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useSnackbar } from './useSnackbar';
import { useDialog } from './useDialog';
import { ConfirmDialog } from '../components/dialogs';
import { usePatchOneScoringMutation } from '../services/ScoringApi';

interface UseChangeUnderwriterStatusProps {
  scoringId: string | number | null;
  refetch: () => void;
}

export const useChangeUnderwriterStatus = ({
  scoringId,
  refetch,
}: UseChangeUnderwriterStatusProps) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const [openConfirmation] = useDialog(ConfirmDialog);
  const [patch] = usePatchOneScoringMutation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    openConfirmation({
      text: t('Вы уверены что хотите поменять статус?'),
      resolve: () => {
        patch({ id: scoringId, body: { status_underwriter: e.target.value } })
          .unwrap()
          .then(refetch)
          .catch((e) => {
            const message = get(e, 'data.message');
            showMessage(message, { severity: 'error' });
          });
      },
    });
  };

  return { handleChange };
};
