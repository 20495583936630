import { Print } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IRequisites } from '../../models';
import { CREDITS_SCHEDULE_COLUMNS, CREDITS_SCHEDULE_EXTRA_COLUMNS } from '../../sources/columns';
import { DataTable } from '../data-table';
import { Requisites } from '../requisites';

interface Props {
  data: any;
}

const AARequisites: IRequisites = {
  name: 'Шайхонтахурский филиал АКБ «ASIA ALLIANCE BANK»',
  address: '100128, г.Ташкент, Шайхонтахурский район, улица Шайхонтохур, 87а',
  managerTitle: 'Вр.и.о. Управляющий',
  manager: 'Мирзабеков Х. Х.____________',
  accountantTitle: 'Главный бухгалтер',
  accountant: 'Цой Л. С.____________',
};

export const CreditDetailsSchedule: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const schedules = get(data, 'data.schedules', []);

  const merchantRequisites: IRequisites = {
    name: get(data, 'data.application.merchant.company_name'),
    address: get(data, 'data.application.merchant.company_legal_address'),
    phone: get(data, 'data.application.merchant.company_phone'),
    manager: get(data, 'data.application.merchant.director_name') + '____________',
    accountant: get(data, 'data.application.merchant.chief_accountant_name') + '____________',
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <Box className="no-print">
        <DataTable data={schedules} columns={CREDITS_SCHEDULE_COLUMNS} mb={2} />
      </Box>
      {/*//Print only*/}
      <Box className="only-print">
        <DataTable data={schedules} columns={CREDITS_SCHEDULE_EXTRA_COLUMNS} mb={4} />
        <Typography component="div" variant="h6" textAlign="center" mb={2}>
          {t('Юридические адреса и банковские реквизиты сторон')}
        </Typography>
        <Grid container mb={2}>
          <Grid item xs={6}>
            <Typography component="div" variant="h5" textTransform="uppercase">
              {t('Банк')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div" variant="h5" textTransform="uppercase" textAlign="right">
              {t('Заёмщик')}
            </Typography>
          </Grid>
          <Grid item xs={6} border="1px solid" borderColor="grey.A100" p={2}>
            <Requisites data={AARequisites} />
          </Grid>{' '}
          <Grid item xs={6} border="1px solid" borderColor="grey.A100" borderLeft="none" p={2}>
            <Requisites data={merchantRequisites} />
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="right" className="no-print">
        <Button variant="contained" onClick={handlePrint} size="small" startIcon={<Print />}>
          {t('Распечатать график платежей')}
        </Button>
      </Box>
    </div>
  );
};
