import { IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { IconDotsHorizontal } from '../../components/icons';
import { StatusButtonCreditSchedule } from '../../components/status-button';
import { SumCurrency } from '../../components/sum-currency';
import { ROUTES } from '../../core/constants';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { IColumn, ICreditSchedule } from '../../models';

export const CREDITS_SCHEDULE_COLUMNS: IColumn[] = [
  {
    dataKey: '',
    label: '№',
    renderItem: ({ loan_id, id }: ICreditSchedule, idx) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.CREDITS.DETAILS.PAYMENTS, { id: loan_id, schedule: id })}
        variant="body2"
        color="primary.main"
      >
        {idx + 1}
      </Typography>
    ),
  },
  {
    dataKey: 'loan_balance',
    label: 'Остаток по кредиту',
    sortable: true,
    renderItem: ({ loan_balance }: ICreditSchedule) => (
      <SumCurrency sum={loan_balance} currency="сум" />
    ),
  },
  {
    dataKey: 'payment_loan',
    label: 'Основной долг',
    sortable: true,
    renderItem: ({ payment_loan }: ICreditSchedule) => (
      <SumCurrency sum={payment_loan} currency="сум" />
    ),
  },
  {
    dataKey: 'payment_percentage',
    label: 'Проценты',
    sortable: true,
    renderItem: ({ payment_percentage }: ICreditSchedule) => (
      <SumCurrency sum={payment_percentage} currency="сум" />
    ),
  },
  {
    dataKey: 'payment_amount',
    label: 'Сумма платежа',
    sortable: true,
    renderItem: ({ payment_amount }: ICreditSchedule) => (
      <SumCurrency sum={payment_amount} currency="сум" />
    ),
  },
  {
    dataKey: 'payment_at',
    label: 'Дата платежа',
    sortable: true,
    renderItem: ({ payment_at }: ICreditSchedule) =>
      payment_at ? format(new Date(payment_at), 'dd.MM.yyyy') : '',
  },
  {
    dataKey: 'status',
    label: 'Статус',
    sortable: true,
    renderItem: ({ status }: ICreditSchedule) => <StatusButtonCreditSchedule status={status} />,
  },
  {
    dataKey: '',
    label: '',
    width: 60,
    renderItem: ({ loan_id, id }: ICreditSchedule) => (
      <IconButton
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.CREDITS.DETAILS.PAYMENTS, { id: loan_id, schedule: id })}
        size="small"
        color="primary"
      >
        <IconDotsHorizontal fontSize="small" />
      </IconButton>
    ),
  },
];

export const CREDITS_SCHEDULE_EXTRA_COLUMNS: IColumn[] = [
  {
    dataKey: '',
    label: '№',
    renderItem: ({ loan_id, id }: ICreditSchedule, idx) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.CREDITS.DETAILS.PAYMENTS, { id: loan_id, schedule: id })}
        variant="body2"
        color="primary.main"
      >
        {idx + 1}
      </Typography>
    ),
  },
  {
    dataKey: 'loan_balance',
    label: 'Остаток по кредиту',
    sortable: true,
    renderItem: ({ loan_balance }: ICreditSchedule) => (
      <SumCurrency sum={loan_balance} currency="сум" />
    ),
  },
  {
    dataKey: 'payment_loan',
    label: 'Основной долг',
    sortable: true,
    renderItem: ({ payment_loan }: ICreditSchedule) => (
      <SumCurrency sum={payment_loan} currency="сум" />
    ),
  },
  {
    dataKey: 'payment_percentage',
    label: 'Проценты',
    sortable: true,
    renderItem: ({ payment_percentage }: ICreditSchedule) => (
      <SumCurrency sum={payment_percentage} currency="сум" />
    ),
  },
  {
    dataKey: 'payment_amount',
    label: 'Сумма платежа',
    sortable: true,
    renderItem: ({ payment_amount }: ICreditSchedule) => (
      <SumCurrency sum={payment_amount} currency="сум" />
    ),
  },
  {
    dataKey: 'payment_at',
    label: 'Дата платежа',
    sortable: true,
    renderItem: ({ payment_at }: ICreditSchedule) =>
      payment_at ? format(new Date(payment_at), 'dd.MM.yyyy') : '',
  },
];
