import { ComponentType, ReactElement, forwardRef, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  Slide,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import { IconClose } from '../../icons';

export interface DialogProps extends Omit<MuiDialogProps, 'open' | 'onClose'> {
  title?: string;
  popDialog?: any;
}

interface LocalTransitionProps extends TransitionProps {
  children: ReactElement;
}

const Transition = forwardRef(({ children, ...props }: LocalTransitionProps, ref: Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props}>
    {children}
  </Slide>
));

export const BaseDialog: ComponentType<DialogProps> = ({
  title,
  popDialog,
  children,
  ...props
}) => {
  return (
    <MuiDialog open onClose={popDialog} TransitionComponent={Transition} {...props}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pt={2.5}
        pl={3}
        pr={1}
      >
        <Typography variant="h2" component="div" color="primary.dark">
          {title}
        </Typography>
        <IconButton onClick={popDialog}>
          <IconClose />
        </IconButton>
      </Stack>
      <MuiDialogContent>
        <Box width={426}>{children}</Box>
      </MuiDialogContent>
    </MuiDialog>
  );
};
