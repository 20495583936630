import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../core/constants';
import { dynamicRoute } from '../core/utils/routes.utils';
import { rtkBaseQuery } from '../core/utils/rtk.utils';
import { IAttachment, ICreditsDetails, ICreditsList } from '../models';

export const loansApi = createApi({
  reducerPath: 'loansApi',
  baseQuery: rtkBaseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    fetchAllLoans: build.query<ICreditsList, Record<string, any>>({
      query: (params = {}) => ({
        url: ENDPOINTS.LOANS.FETCH_ALL,
        params,
      }),
    }),
    fetchOneLoan: build.query<ICreditsDetails, string | number | null>({
      query: (id) => ({
        url: dynamicRoute(ENDPOINTS.LOANS.FETCH_ONE, { id }),
      }),
    }),
    patchOneLoan: build.mutation<ICreditsDetails, Record<string, any>>({
      query: ({ id, body }) => ({
        url: dynamicRoute(ENDPOINTS.LOANS.PATCH_ONE, { id }),
        method: 'PATCH',
        body,
      }),
    }),
    completeOneLoan: build.mutation<ICreditsDetails, string | number | null>({
      query: (id) => ({
        url: dynamicRoute(ENDPOINTS.LOANS.COMPLETE_ONE, { id }),
        method: 'POST',
      }),
    }),
    addLoanPayment: build.mutation<ICreditsDetails, { id: string | number; payments: any[] }>({
      query: ({ id, payments }) => ({
        url: dynamicRoute(ENDPOINTS.LOANS.ADD_PAYMENT, { id }),
        method: 'POST',
        body: { payments },
      }),
    }),
    attachLoanPayment: build.mutation<IAttachment, any>({
      query: ({ id, body }) => ({
        url: dynamicRoute(ENDPOINTS.LOANS.ATTACH, { id }),
        method: 'POST',
        body,
      }),
    }),
    exportLoans: build.mutation({
      query: (params) => ({
        url: ENDPOINTS.LOANS.EXPORT,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useFetchAllLoansQuery,
  useFetchOneLoanQuery,
  usePatchOneLoanMutation,
  useCompleteOneLoanMutation,
  useAddLoanPaymentMutation,
  useAttachLoanPaymentMutation,
  useExportLoansMutation,
} = loansApi;
