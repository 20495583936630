import { ComponentType } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { AuthLogin } from '../../../components/auth';
import { useTranslation } from 'react-i18next';

export const HomePage: ComponentType = () => {
  const { t } = useTranslation();

  const title = t('Добро пожаловать');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box width={{ xs: 320, sm: 400, md: 480 }} m="auto">
        <Typography variant="h1" textAlign="center" mb={2}>
          {t('Авторизация')}
        </Typography>
        <Card>
          <CardContent>
            <AuthLogin />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
