import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateOneUserMutation, usePatchOneUserMutation } from '../../../services/UsersApi';
import { RolesEnum } from '../../inputs/select-user-role';
import { BaseDialog, DialogProps } from '../base-dialog';

interface Props extends DialogProps {
  onClose: () => void;
  showMessage: (message: string, options?: any) => void;
  user?: any;
}

export const AddUserDialog: FC<Props> = ({ user, onClose, showMessage, ...props }) => {
  // const { showMessage } = useSnackbar();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [createUser] = useCreateOneUserMutation();
  const [update] = usePatchOneUserMutation();

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      second_name: '',
      login: '',
      email: '',
      password: '',
      c_password: '',
      role_code: '',
      ...user,
    },
    // validationSchema: object().shape({
    //   first_name: string().required(t('Поле обязательно для заполнения')),
    //   last_name: string().required(t('Поле обязательно для заполнения')),
    //   second_name: string(),
    //   login: string().required(t('Поле обязательно для заполнения')),
    //   email: string().required(t('Поле обязательно для заполнения')),
    //   password: string().required(t('Поле обязательно для заполнения')),
    //   c_password: string().oneOf([ref('password'), null], 'Пароль не совпадает'),
    //   role_code: string().required(t('Поле обязательно для заполнения')),
    // }),
    onSubmit: (values) => {
      if (user) {
        const newValues = Object.fromEntries(Object.entries(values).filter(([, value]) => value));
        update({
          id: user.id,
          ...Object.keys(newValues).reduce((acc: Record<string, any>, key) => {
            if (newValues[key] !== user[key]) {
              acc[key] = newValues[key];
            }
            return acc;
          }, {}),
        })
          .unwrap()
          .then(onClose)
          .catch((error) => {
            showMessage(error.message, { severity: 'error' });
          });
      } else {
        if (values.password !== values.c_password) {
          showMessage('Пароли не совпадают', { severity: 'error' });
          return;
        }
        createUser(values)
          .unwrap()
          .then(onClose)
          .catch((error) => {
            showMessage(error.message, { severity: 'error' });
          });
      }
    },
  });

  const handleClickCancel = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <BaseDialog {...props}>
      <Stack gap={2} component="form" onSubmit={formik.handleSubmit}>
        <TextField label="Фамилия" {...formik.getFieldProps('last_name')} required={!user} />
        <TextField label="Имя" {...formik.getFieldProps('first_name')} required={!user} />
        <TextField label="Отчество" {...formik.getFieldProps('second_name')} />

        <TextField label="Логин" {...formik.getFieldProps('login')} required={!user} />
        <TextField label="Email" {...formik.getFieldProps('email')} required={!user} />

        <TextField
          label="Пароль"
          type={showPassword ? 'text' : 'password'}
          required={!user}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...formik.getFieldProps('password')}
        />

        {!user && (
          <TextField
            label="Повторите пароль"
            type={showPassword ? 'text' : 'password'}
            required={!user}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword2((prev) => !prev)}
                    edge="end"
                  >
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...formik.getFieldProps('c_password')}
          />
        )}

        <TextField label="Роль" select required={!user} {...formik.getFieldProps('role_code')}>
          {Object.keys(RolesEnum).map(
            (key) =>
              key !== 'guest' && (
                <MenuItem value={key} key={key}>
                  {t(key, RolesEnum[key as keyof typeof RolesEnum])}
                </MenuItem>
              )
          )}
        </TextField>

        <Box display="flex" justifyContent="space-between">
          <Button variant="contained" color="info" onClick={handleClickCancel}>
            Отмена
          </Button>
          <Button variant="contained" type="submit">
            {user ? 'Обновить' : 'Добавить'}
          </Button>
        </Box>
      </Stack>
    </BaseDialog>
  );
};
