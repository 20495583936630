import { ComponentType, useState, MouseEvent } from 'react';
import { get, map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { UserCard } from '../user-card';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { RoleEnums } from '../../../core/enums';
import { useInfoQuery } from '../../../services/MeApi';
import { USER_MENU } from '../../../core/menus';

export const UserMenu: ComponentType = () => {
  const navigate = useNavigate();
  const { data } = useInfoQuery({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const role = get(data, 'data.role_code', 'guest');
  const menu = get(USER_MENU, role, RoleEnums.GUEST);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    navigate(path);
    handleClose();
  };

  return (
    <>
      <Box
        component={Button}
        id="user-menu-button"
        onClick={handleOpen}
        sx={{ textAlign: 'left', lineHeight: 1 }}
        role="button"
        aria-haspopup="true"
        aria-controls={open ? 'user-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
      >
        <UserCard />
      </Box>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
      >
        {map(menu, ({ label, path, icon }, idx) => (
          <MenuItem onClick={() => handleMenuItemClick(path)} key={idx}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
