import { Box, Button, MenuItem, TableBody, TableHead, TableRow, TextField } from '@mui/material';
import { map } from 'lodash';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataRow } from '../../core/utils/data.utils';
import { usePatchOneMerchantMutation } from '../../services/MerchantsApi';
import { StyledTable, StyledTableCell, StyledTableRow } from '../data-table/styled';

interface Props {
  data: any;
}

export const MerchantDetailsInfo: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { id } = data.data;
  const [oked, setOked] = useState(data.data.company_oked);
  const [legalAddress, setLegalAddress] = useState(data.data.company_legal_address);
  const [foundersCount, setFoundersCount] = useState(data.data.company_founders_count);
  const [region, setRegion] = useState(data.data.company_region);
  const [productCategory, setProductCategory] = useState(data.data.product_category);

  const [patch] = usePatchOneMerchantMutation();

  const handleSaveValue = (key: string, value: string | number) => {
    patch({ id: data.data.id, body: { [key]: value } });
  };

  const rows = dataRow(data, [
    {
      label: 'Мерчант',
      field: ['company_name'],
    },
    {
      label: 'ИНН',
      field: ['company_inn'],
    },
    {
      label: 'ОКЭД',
      field: ['company_oked'],
      renderItem: (value: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            fullWidth
            size="small"
            defaultValue={value}
            onChange={(e) => setOked(e.target.value)}
            sx={{ maxWidth: 400 }}
          />
          <Button size="small" onClick={() => handleSaveValue('company_oked', oked)}>
            Сохранить
          </Button>
        </Box>
      ),
    },
    {
      label: 'Юридический адрес',
      field: ['company_legal_address'],
      renderItem: (value: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            fullWidth
            size="small"
            defaultValue={value}
            onChange={(e) => setLegalAddress(e.target.value)}
            sx={{ maxWidth: 400 }}
          />
          <Button
            size="small"
            onClick={() => handleSaveValue('company_legal_address', legalAddress)}
          >
            Сохранить
          </Button>
        </Box>
      ),
    },
    {
      label: 'Регион компании',
      field: ['company_region'],
      renderItem: (value: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            fullWidth
            size="small"
            defaultValue={value}
            onChange={(e) => setRegion(e.target.value)}
            sx={{ maxWidth: 400 }}
          />
          <Button size="small" onClick={() => handleSaveValue('company_region', region)}>
            Сохранить
          </Button>
        </Box>
      ),
    },
    {
      label: 'Категория товаров',
      field: ['product_category'],
      renderItem: (value: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            fullWidth
            size="small"
            defaultValue={value}
            onChange={(e) => setProductCategory(e.target.value)}
            sx={{ maxWidth: 400 }}
          />
          <Button size="small" onClick={() => handleSaveValue('product_category', productCategory)}>
            Сохранить
          </Button>
        </Box>
      ),
    },
    {
      label: 'Количество учредителей',
      field: ['company_founders_count'],
      renderItem: (value: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            fullWidth
            type="number"
            size="small"
            defaultValue={value}
            onChange={(e) => setFoundersCount(e.target.value)}
            sx={{ maxWidth: 400 }}
            inputProps={{ min: 1 }}
          />
          <Button
            size="small"
            onClick={() => handleSaveValue('company_founders_count', foundersCount)}
          >
            Сохранить
          </Button>
        </Box>
      ),
    },
    {
      label: 'Пол учредителей',
      field: ['gender'],
      renderItem: (value: any) => {
        return (
          <TextField
            select
            value={value}
            size="small"
            onChange={(e) => handleSaveValue('gender', e.target.value)}
          >
            <MenuItem value="male">Мужской</MenuItem>
            <MenuItem value="female">Женский</MenuItem>
            <MenuItem value="shared">Совместный</MenuItem>
          </TextField>
        );
      },
    },
    {
      label: 'Директор',
      field: ['director_name', ',', 'director_passport_series', 'director_passport_id'],
    },
    {
      label: 'Бухгалтер',
      field: [
        'chief_accountant_name',
        ',',
        'chief_accountant_passport_series',
        'chief_accountant_passport_id',
      ],
    },
    {
      label: 'Контактное лицо',
      field: ['contact_name', ',', 'contact_phone', ',', 'contact_email'],
    },
    {
      label: 'Банк / РС',
      field: ['bank_name', ', МФО: ', 'bank_mfo', ',', 'bank_account'],
    },
  ]);

  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <StyledTableCell width={250}>{t('ID')}:</StyledTableCell>
          <StyledTableCell>{id}</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map(rows, (row, idx) => (
          <StyledTableRow key={idx}>
            <StyledTableCell width={250}>{t(row.label)}:</StyledTableCell>
            <StyledTableCell>{row.value}</StyledTableCell>
          </StyledTableRow>
        ))}
        {/*<StyledTableRow>
      <StyledTableCell width={250}>{t('Статус')}:</StyledTableCell>
      <StyledTableCell>
        {isChangeStatusVisible ? (
          <SelectStatus
            onChange={handleChangeMerchantStatus}
            options={statuses}
            labels={MERCHANTS_STATUSES_LABELS}
            value={status}
            size="small"
            fullWidth
          />
        ) : (
          <StatusButtonMerchant status={status} />
        )}
      </StyledTableCell>
    </StyledTableRow>*/}
        {/*<StyledTableRow className="no-print">
      <StyledTableCell width={250}>{t('Документы')}:</StyledTableCell>
      <StyledTableCell>
        <FilesUploader
          onAttach={handleAttach}
          onDelete={handleDelete}
          fileType={FileTypesEnums.MERCHANT}
          docType={DocumentTypesEnums.STATEMENT}
          attachments={attachments}
        />
      </StyledTableCell>
    </StyledTableRow>*/}
      </TableBody>
    </StyledTable>
  );
};
