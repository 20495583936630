import { ComponentType, PropsWithChildren, useState, useMemo } from 'react';
import { LayoutContext } from '../contexts';
import { ILayout } from '../../models';

export const LayoutProvider: ComponentType<PropsWithChildren<unknown>> = ({ children }) => {
  const [options, setOptions] = useState<ILayout | null>(null);

  const value = useMemo(
    () => ({
      options,
      setOptions,
    }),
    [options]
  );

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};
