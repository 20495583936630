import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../core/constants';
import { dynamicRoute } from '../core/utils/routes.utils';
import { rtkBaseQuery } from '../core/utils/rtk.utils';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: rtkBaseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['Users'],
  endpoints: (build) => ({
    fetchAllUsers: build.query({
      query: (params = {}) => ({
        url: ENDPOINTS.USERS.FETCH_ALL,
        params,
      }),
      providesTags: ['Users'],
    }),
    fetchOneUser: build.query({
      query: ({ id, params = {} }) => ({
        url: dynamicRoute(ENDPOINTS.USERS.FETCH_ONE, { id }),
        params,
      }),
      providesTags: (_, __, id) => [{ type: 'Users', id }],
    }),
    createOneUser: build.mutation({
      query: (body) => ({
        url: ENDPOINTS.USERS.CREATE_ONE,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    patchOneUser: build.mutation({
      query: ({ id, ...body }) => ({
        url: dynamicRoute(ENDPOINTS.USERS.PATCH_ONE, { id }),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, id) => [{ type: 'Users', id }, 'Users'],
    }),
    removeOneUser: build.mutation({
      query: (id) => {
        return {
          url: dynamicRoute(ENDPOINTS.USERS.PATCH_ONE, { id }),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Users'],
    }),
    fetchUsersByRole: build.query({
      query: (params) => ({
        url: ENDPOINTS.USERS.DROPDOWN,
        params,
      }),
    }),
  }),
});

export const {
  useFetchAllUsersQuery,
  useFetchOneUserQuery,
  useCreateOneUserMutation,
  usePatchOneUserMutation,
  useRemoveOneUserMutation,
  useFetchUsersByRoleQuery,
} = usersApi;
