import { separateNumber } from '../../core/utils/format.utils';
import { StatusButtonScoring } from '../../components/status-button';
import { IRow } from '../../models';

export const SCORING_ROWS: IRow[] = [
  {
    label: 'Годовая ставка',
    field: ['loan_percentage_annual'],
    renderItem: (value: any) => {
      return (value ? separateNumber(Number(value)) : 0) + '%';
    },
  },
  {
    label: 'Месячная ставка',
    field: ['loan_percentage_monthly'],
    renderItem: (value: any) => {
      return (value ? separateNumber(Number(value)) : 0) + '%';
    },
  },
  {
    label: 'Порог скоринга',
    field: ['threshold'],
  },
  {
    label: 'Статус',
    field: ['status'],
    renderItem: (value: any) => <StatusButtonScoring status={value} />,
  },
];
