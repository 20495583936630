import { forEach } from 'lodash';
import { Box, styled, Tab } from '@mui/material';

interface LinkTabProps {
  label?: string;
  href?: string;
  pathParams?: Record<string, any>;
}

export const LinkTab = ({href, pathParams, ...props}: LinkTabProps) => {
  if(href) {
    forEach(pathParams, (value, key) => {
      href = (href || '').replace(`:${key}`, value);
    });
  }

  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      sx={{ minHeight: 56}}
      {...{href, ...props} }
    />
  );
}

export const ItemWrapper = styled(Box)(({theme}) => ({
  position: 'relative',
  marginLeft: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 1,
    height: theme.spacing(3),
    backgroundColor: '#E5E9EB',
  },
}));