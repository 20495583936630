import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find, get, map } from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDialog, useLayout } from '../../../../hooks';
import { LoaderPage } from '../../../../components/loader';
import { DataTable } from '../../../../components/data-table';
import { Uploader } from '../../../../components/inputs';
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from '../../../../components/data-table/styled';
import { useAttachLoanPaymentMutation, useFetchOneLoanQuery } from '../../../../services/LoansApi';
import { CREDITS_SCHEDULE_ROWS } from '../../../../sources/rows';
import { CREDITS_PAYMENTS_COLUMNS } from '../../../../sources/columns';
import { AddPaymentDialog } from '../../../../components/dialogs';
import { CreditScheduleStatusesEnums } from '../../../../core/enums';

export const CreditsPaymentsPage: ComponentType = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [openAddPayment] = useDialog(AddPaymentDialog);
  const { handleChangeParams } = useLayout();
  const [attach] = useAttachLoanPaymentMutation();

  const id = get(params, 'id', null);
  const { data, isFetching, isLoading, refetch } = useFetchOneLoanQuery(id);
  const title = get(data, 'data.application.merchant.company_name');
  const schedules = get(data, 'data.schedules', []);
  const schedule = find(schedules, (x) => x.id === Number(params.schedule));
  const payments = get(schedule, 'payments', []);
  const status = get(schedule, 'status');

  const rows = map(CREDITS_SCHEDULE_ROWS, (col) => {
    const field = get(col, 'field', []);
    const value = map(field, (f) => get(schedule, f, '')).join(' ');

    return {
      label: col.label,
      value: col.renderItem ? col.renderItem(value) : value,
    };
  });

  const isAddPaymentVisible = ![
    CreditScheduleStatusesEnums.PAID,
    CreditScheduleStatusesEnums.PAID_BEFORE,
    CreditScheduleStatusesEnums.PAID_LATER,
  ].includes(status);

  const handleAttach = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    attach({ id: params.schedule, body: formData }).unwrap().then(refetch);
  };

  const handleAddPayment = () => {
    openAddPayment({ title: t('Добавить платеж'), id: params.schedule, onSuccess: refetch });
  };

  useEffect(() => {
    handleChangeParams({ title, backButton: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, params]);

  if (isLoading || isFetching) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box component={Card} display="flex" flexDirection="column" flex={2}>
        <Box component={CardContent} flex={2}>
          <Grid container direction="column" spacing={4} mb={4}>
            <Grid item xs={4}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width={250}>{t('ID')}:</StyledTableCell>
                    <StyledTableCell>{id}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(rows, (row, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell width={250}>{t(row.label)}:</StyledTableCell>
                      <StyledTableCell>{row.value}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </Grid>
            <Grid item xs={8}>
              <DataTable data={payments} columns={CREDITS_PAYMENTS_COLUMNS} />
            </Grid>
          </Grid>
          {/*<Uploader onAttach={handleAttach} />*/}
          {isAddPaymentVisible && (
            <Box textAlign="right">
              <Button onClick={handleAddPayment} variant="contained" color="primary">
                {t('Добавить оплату')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
