import { Stack, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { get, isEmpty, map } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { numberFormat } from '../../core/utils/format.utils';
import { useFetchOneApplicationQuery } from '../../services/ApplicationsApi';
import { StyledTable, StyledTableCell, StyledTableRow } from '../data-table/styled';

interface Props {}

export const ApplicationDetailsFinance: FC<Props> = () => {
  const { t } = useTranslation();

  const params = useParams();
  const id = get(params, 'id', null);
  const { data } = useFetchOneApplicationQuery(id);

  const scoring = get(data, 'data.scoring_result', []);
  const finances = get(scoring, 'finances');
  const sumOfTerm = get(scoring, 'sum_of_term');

  if (isEmpty(finances)) {
    return null;
  }

  return (
    <Stack gap={2}>
      {!isEmpty(sumOfTerm) && (
        <Typography variant="h5">
          {t('За последние')} {get(sumOfTerm, 'term')} {t('месяцев')}{' '}
          {numberFormat(get(sumOfTerm, 'sum'))} {t('сум')} (20% ={' '}
          {numberFormat(get(sumOfTerm, 'sum_20', 0))} {t('сум')})
        </Typography>
      )}
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('Дата')}</StyledTableCell>
            <StyledTableCell>{t('Валовая выручка')}</StyledTableCell>
            {/*<StyledTableCell>{t('Возвраты')}</StyledTableCell>*/}
            {/*<StyledTableCell>{t('Скидки')}</StyledTableCell>*/}
            <StyledTableCell>{t('Себестоимость')}</StyledTableCell>
            {/*<StyledTableCell>{t('Валовая прибыль')}</StyledTableCell>*/}
            <StyledTableCell>{t('Остаток по себестоимости')}</StyledTableCell>
            {/*<StyledTableCell>{t('Остаток по цене продажи')}</StyledTableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(finances, (finance, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell width={150}>{finance.date}</StyledTableCell>
              <StyledTableCell>
                {numberFormat(finance.gross_revenue)} {t('сум')}
              </StyledTableCell>
              {/*<StyledTableCell>{numberFormat(finance.discount)} {t('сум')}</StyledTableCell>*/}
              {/*<StyledTableCell>{numberFormat(finance.returns)} {t('сум')}</StyledTableCell>*/}
              <StyledTableCell>
                {numberFormat(finance.cost_price)} {t('сум')}
              </StyledTableCell>
              {/*<StyledTableCell>{numberFormat(finance.gross_profit)} {t('сум')}</StyledTableCell>*/}
              <StyledTableCell>
                {numberFormat(finance.stock_amount_cost)} {t('сум')}
              </StyledTableCell>
              {/*<StyledTableCell>{numberFormat(finance.stock_amount_selling)} {t('сум')}</StyledTableCell>*/}
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Stack>
  );
};
