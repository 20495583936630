import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Button, Stack } from '@mui/material';
import { Add, Autorenew } from '@mui/icons-material';
import { useDialog } from '../../../hooks';
import { ConfirmDialog } from '../../dialogs';
import { IAttachment } from '../../../models';
import { DocumentTypesEnums, FileTypesEnums } from '../../../core/enums';

interface OnGenerateProps {
  fileType: FileTypesEnums | '';
  docType: DocumentTypesEnums | '';
}

interface FileGeneratorProps extends BoxProps {
  onGenerate?: (props: OnGenerateProps) => void;
  fileType?: FileTypesEnums | '';
  docType?: DocumentTypesEnums | '';
  attachment?: IAttachment;
}

export const FileGenerator: ComponentType<FileGeneratorProps> = ({
  onGenerate,
  fileType,
  docType,
  attachment,
  ...props
}) => {
  const { t } = useTranslation();
  const [openConfirmation] = useDialog(ConfirmDialog);
  const [attachmentFileType, setAttachmentFileType] = useState<FileTypesEnums | ''>('');
  const [attachmentDocType, setAttachmentDocType] = useState<DocumentTypesEnums | ''>('');

  const handleGenerate = () => {
    if (onGenerate) {
      onGenerate({ fileType: attachmentFileType, docType: attachmentDocType });
    }
  };

  const handleReGenerate = (docType: DocumentTypesEnums) => {
    openConfirmation({
      text: t('Вы уверены что хотите сгенерировать документ снова?'),
      resolve: () => {
        if (onGenerate) {
          onGenerate({ fileType: attachmentFileType, docType });
        }
      },
    });
  };

  useEffect(() => {
    if (fileType) setAttachmentFileType(fileType);
  }, [fileType]);

  useEffect(() => {
    if (docType) setAttachmentDocType(docType);
  }, [docType]);

  return (
    <Box component={Stack} {...props} spacing={1}>
      {attachment ? (
        <Box
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid"
          borderColor="grey.A100"
        >
          <Box>
            <a href={attachment.url} target="_blank" rel="noreferrer">
              {/*{attachment.file_name}*/}
              {t('Скачать')}
            </a>
          </Box>
          <Box component={Stack} direction="row" alignItems="center" spacing={1}>
            <Button
              onClick={() => handleReGenerate(attachment.document_type as DocumentTypesEnums)}
              variant="contained"
              startIcon={<Autorenew />}
              color="info"
              size="small"
            >
              {t('Сгенерировать снова')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid"
          borderColor="grey.A100"
        >
          <Box>{t('Файл не сгенерирован')}</Box>
          <Box component={Stack} direction="row" spacing={1}>
            <Button onClick={handleGenerate} startIcon={<Add />} color="primary" size="small">
              {t('Сгенериовать')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
