import { format } from 'date-fns';
import { SumCurrency } from '../../components/sum-currency';
import { ICreditPayment, IColumn } from '../../models';

export const CREDITS_PAYMENTS_COLUMNS: IColumn[] = [
  {
    dataKey: '',
    label: '№',
    renderItem: ({ id }: ICreditPayment, idx) => idx + 1,
  },
  {
    dataKey: 'amount',
    label: 'Сумма',
    sortable: true,
    renderItem: ({ amount }: ICreditPayment) => <SumCurrency sum={amount} currency="сум" />,
  },
  {
    dataKey: 'payment_at',
    label: 'Дата платежа',
    sortable: true,
    renderItem: ({ payment_at }: ICreditPayment) =>
      payment_at ? format(new Date(payment_at), 'dd.MM.yyyy') : '',
  },
];
