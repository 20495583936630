import { Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

const ChartWrapper: FC<Props> = ({ title, children }) => {
  return (
    <Stack gap={2}>
      <Typography color="primary">{title}</Typography>
      {children}
    </Stack>
  );
};

export default ChartWrapper;
