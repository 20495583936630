import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: ['Inter', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontSize: 26,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h4: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
  },
  button: {
    fontSize: 14,
    fontWeight: 500,
  },
  overline: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.35,
  },
};

export default typography;
