import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../base-dialog';
import { Button, Stack, Typography } from '@mui/material';

interface LoginDialogProps {
  title?: string;
  text?: string;
  popDialog?: any;
  resolve?: () => void;
  reject?: () => void;
}

export const ConfirmDialog: ComponentType<LoginDialogProps> = ({
  title,
  text,
  resolve,
  reject,
  popDialog,
  ...props
}) => {
  const { t } = useTranslation();
  const dialogTitle = title || t('Подтверждение');

  const handleClose = () => {
    if (popDialog) popDialog();
  };

  const handleResolve = () => {
    if (resolve) resolve();
    handleClose();
  };

  const handleReject = () => {
    if (reject) reject();
    handleClose();
  };

  return (
    <BaseDialog {...{ ...props, popDialog: handleReject }} maxWidth="sm" title={dialogTitle}>
      {text && (
        <Typography variant="body1" mb={3}>
          {text}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
        <Button onClick={handleClose} variant="contained" color="error">
          {t('Отмена')}
        </Button>
        <Button onClick={handleResolve} variant="contained" color="success">
          {t('ОК')}
        </Button>
      </Stack>
    </BaseDialog>
  );
};
