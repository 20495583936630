import { Box, Button, Card, CardContent, Pagination, Stack, TextField } from '@mui/material';
import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../components/data-table';
import {
  SelectPerPage,
  SelectScoringStatus,
  SelectUnderwriterStatus,
} from '../../../components/inputs';
import { LoaderPage } from '../../../components/loader';
import { useFilter, useLayout, useResources } from '../../../hooks';
import { useFetchAllScoringQuery } from '../../../services/ScoringApi';
import { SCORING_COLUMNS } from '../../../sources/columns';

export const ScoringPage: ComponentType = () => {
  const { t } = useTranslation();
  const { handleChangeParams } = useLayout();
  const { getFieldValue, handleSubmit } = useFilter();
  const {
    dataList,
    currentPage,
    perPage,
    pagesCount,
    orderBy,
    orderDirection,
    isFetching,
    isLoading,
    handleChangeLimit,
    handleChangePage,
    handleChangeSort,
  } = useResources(useFetchAllScoringQuery);

  const title = t('Скоринговые данные');

  useEffect(() => {
    handleChangeParams({ title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Box component={Card} mb={2}>
        <Box component={CardContent}>
          <Stack>
            <Stack
              onSubmit={handleSubmit}
              component="form"
              action="/"
              method="GET"
              direction="row"
              spacing={2}
            >
              <Box flex="1 1 auto">
                <TextField
                  defaultValue={getFieldValue('merchant[company_name]')}
                  name="merchant[company_name]"
                  label={t('Мерчант')}
                  size="small"
                  fullWidth
                />
              </Box>
              <SelectScoringStatus
                defaultValue={getFieldValue('status')}
                name="status"
                label={t('Статус скоринга')}
                size="small"
                sx={{ minWidth: 120 }}
              />
              <SelectUnderwriterStatus
                defaultValue={getFieldValue('status_underwriter')}
                name="status_underwriter"
                label={t('Статус андеррайтера')}
                size="small"
                prompt
                sx={{ minWidth: 120 }}
              />
              <Box>
                <Box height={22} />
                <Button type="submit" variant="contained" size="small">
                  {t('Фильтровать')}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Box component={Card} display="flex" flexDirection="column" flex={2}>
        <Box component={CardContent} flex={2}>
          <DataTable
            data={dataList}
            isLoading={isFetching}
            columns={SCORING_COLUMNS}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onOrder={handleChangeSort}
          />
        </Box>
        <Box
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={1.5}
          p={1.5}
          borderTop="1px solid #E5E9EB"
        >
          <SelectPerPage onChange={handleChangeLimit} value={perPage} />
          <Pagination onChange={handleChangePage} page={currentPage} count={pagesCount} />
        </Box>
      </Box>
    </>
  );
};
