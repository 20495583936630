import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { rtkQueryErrorLogger } from '../core/utils/rtk.utils';
import { applicationsApi } from '../services/ApplicationsApi';
import { applicationsUnderwriter } from '../services/ApplicationsUnderwriterApi';
import { attachmentsApi } from '../services/AttachmentsApi';
import { authApi } from '../services/AuthApi';
import { dashboardApi } from '../services/DashboardApi';
import { loansApi } from '../services/LoansApi';
import { meApi } from '../services/MeApi';
import { merchantsApi } from '../services/MerchantsApi';
import { reportsApi } from '../services/ReportsApi';
import { scoringApi } from '../services/ScoringApi';
import { templatesApi } from '../services/TemplatesApi';
import { usersApi } from '../services/UsersApi';

export const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [meApi.reducerPath]: meApi.reducer,
  [applicationsApi.reducerPath]: applicationsApi.reducer,
  [applicationsUnderwriter.reducerPath]: applicationsUnderwriter.reducer,
  [attachmentsApi.reducerPath]: attachmentsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [loansApi.reducerPath]: loansApi.reducer,
  [merchantsApi.reducerPath]: merchantsApi.reducer,
  [scoringApi.reducerPath]: scoringApi.reducer,
  [templatesApi.reducerPath]: templatesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
});

const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        rtkQueryErrorLogger,
        authApi.middleware,
        meApi.middleware,
        applicationsApi.middleware,
        applicationsUnderwriter.middleware,
        attachmentsApi.middleware,
        dashboardApi.middleware,
        loansApi.middleware,
        merchantsApi.middleware,
        scoringApi.middleware,
        templatesApi.middleware,
        usersApi.middleware,
        reportsApi.middleware,
      ]),
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
