import { ComponentType } from 'react';
import { Typography, TypographyProps } from '@mui/material';

export const LogoApp: ComponentType<TypographyProps> = (props) => {
  return (
    <Typography variant="h1" color="primary" {...props}>
      МСБ
    </Typography>
  );
};
