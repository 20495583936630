export const ENDPOINT_ROOT = process.env.REACT_APP_API_BASE_URL || '/';

export const ENDPOINTS = {
  AUTH: {
    LOGIN: '/api/login',
  },
  ME: {
    INFO: '/api/user/info',
  },
  USERS: {
    FETCH_ALL: '/api/users',
    CREATE_ONE: '/api/users',
    FETCH_ONE: '/api/users/:id',
    UPDATE_ONE: '/api/users/:id',
    PATCH_ONE: '/api/users/:id',
    DROPDOWN: '/api/users/dropdown',
  },
  APPLICATIONS: {
    FETCH_ALL: '/api/applications',
    FETCH_ONE: '/api/applications/:id',
    PATCH_ONE: '/api/applications/:id',
    HIDE: '/api/applications/:id/hide',
    UNHIDE: '/api/applications/:id/unhide',
    COPY_DOCS: '/api/applications/:id/copy-documents',
    EXPORT: '/api/applications/export',
  },
  APPLICATIONS_UNDERWRITER: {
    FETCH_ALL: '/api/applications-underwriter',
    FETCH_ONE: '/api/applications-underwriter/:id',
  },
  ATTACHMENTS: {
    FETCH_ALL: '/api/attachments',
    FETCH_ONE: '/api/attachments/:id',
    CREATE_ONE: '/api/attachments',
    DELETE_ONE: '/api/attachments/:id',
  },
  DASHBOARD: {
    FETCH_ALL: '/api/dashboards',
    LOAN_DYNAMICS: '/api/dashboards/loan-dynamics',
    APPLICATION_CONVERSION: '/api/dashboards/application-conversions',
  },
  LOANS: {
    FETCH_ALL: '/api/loans',
    FETCH_ONE: '/api/loans/:id',
    PATCH_ONE: '/api/loans/:id',
    COMPLETE_ONE: '/api/loans/:id/complete',
    ADD_PAYMENT: '/api/loan-schedules/:id/payments',
    ATTACH: '/api/loan-schedules/:id/payments-import',
    EXPORT: '/api/loans/export',
  },
  MERCHANTS: {
    FETCH_ALL: '/api/merchants',
    FETCH_ONE: '/api/merchants/:id',
    PATCH_ONE: '/api/merchants/:id',
    EXPORT: '/api/merchants/export',
  },
  SCORING: {
    FETCH_ALL: '/api/scoring-results',
    FETCH_ONE: '/api/scoring-results/:id',
    PATCH_ONE: '/api/scoring-results/:id',
  },
  TEMPLATES: {
    FETCH_ALL: '/api/templates',
    FETCH_ONE: '/api/templates/:id',
    CREATE_ONE: '/api/templates',
    DELETE_ONE: '/api/templates/:id',
  },
};
