import React, { ComponentType, PropsWithChildren } from 'react';
import { StatusButtonCustom } from './StatusButtonCustom';

export const StatusButtonLoan: ComponentType<PropsWithChildren<any>> = ({
  children,
  ...props
}) => {
  return (
    <StatusButtonCustom backgroundColor="#0E73F6" color="#fff" {...props}>
      {children}
    </StatusButtonCustom>
  );
};
