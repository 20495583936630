import { get } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { DetailTabs } from '../../../../components/detail-tabs';
import { LoaderPage } from '../../../../components/loader';
import { MerchantDetailsApplications, MerchantDetailsInfo } from '../../../../components/merchant';
import { ROUTES } from '../../../../core/constants';
import { useLayout } from '../../../../hooks';
import { useFetchOneMerchantQuery } from '../../../../services/MerchantsApi';
//import { MERCHANTS_MENU } from '../../../../core/menus';

export const MerchantsDetailsPage: ComponentType = () => {
  const params = useParams();
  const { handleChangeParams } = useLayout();

  const id = get(params, 'id', null);
  const { data, isFetching, isLoading } = useFetchOneMerchantQuery(id);

  const title = get(data, 'data.company_name');

  useEffect(() => {
    handleChangeParams({
      title,
      backButton: true,
      // tabs: {
      //   menu: MERCHANTS_MENU,
      //   active: 0,
      //   pathParams: params,
      // },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, params]);

  const tabs: DetailTabs = {
    info: {
      label: 'Информация',
      route: ROUTES.CABINET.MERCHANTS.DETAILS.INFO,
      component: <MerchantDetailsInfo data={data} />,
    },
    applications: {
      label: 'Заявки',
      route: ROUTES.CABINET.MERCHANTS.DETAILS.APPLICATIONS,
      component: <MerchantDetailsApplications data={data} />,
    },
  };

  if (isLoading || isFetching) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <DetailTabs tabs={tabs} />
    </>
  );
};
