import { AttachFile } from '@mui/icons-material';
import { Box, BoxProps, Button, Stack } from '@mui/material';
import { ChangeEvent, ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTypesEnums, FileTypesEnums } from '../../../core/enums';
import { IAttachment } from '../../../models';

interface OnAttachProps {
  file: File;
  fileType: FileTypesEnums | '';
  docType: DocumentTypesEnums | '';
}

interface FileUploaderProps extends BoxProps {
  onAttach?: (props: OnAttachProps) => void;
  fileType?: FileTypesEnums | '';
  docType?: DocumentTypesEnums | '';
  attachment?: IAttachment;
}

export const FileUploader: ComponentType<FileUploaderProps> = ({
  onAttach,
  fileType,
  docType,
  attachment,
  ...props
}) => {
  const { t } = useTranslation();
  const [attachmentFileType, setAttachmentFileType] = useState<FileTypesEnums | ''>('');
  const [attachmentDocType, setAttachmentDocType] = useState<DocumentTypesEnums | ''>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (onAttach) {
        onAttach({ file, fileType: attachmentFileType, docType: attachmentDocType });
      }
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (fileType) setAttachmentFileType(fileType);
  }, [fileType]);

  useEffect(() => {
    if (docType) setAttachmentDocType(docType);
  }, [docType]);

  return (
    <Box component={Stack} {...props} spacing={1}>
      {attachment ? (
        <Box
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid"
          borderColor="grey.A100"
        >
          <Box>
            <a href={attachment.url} target="_blank" rel="noreferrer">
              {/*{attachment.file_name}*/}
              {t('Скачать')}
            </a>
          </Box>
          <Button component="label" startIcon={<AttachFile />} color="primary" size="small">
            {t('Загрузить снова')}
            <Box component="input" onChange={handleChange} type="file" display="none" />
          </Button>
        </Box>
      ) : (
        <Box
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid"
          borderColor="grey.A100"
        >
          <Box>{t('Файл не загружен')}</Box>
          <Button component="label" startIcon={<AttachFile />} color="primary" size="small">
            {t('Загрузить')}
            <Box component="input" onChange={handleChange} type="file" display="none" />
          </Button>
        </Box>
      )}
    </Box>
  );
};
