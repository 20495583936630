import { IRow } from '../../models';

export const MERCHANTS_ROWS: IRow[] = [
  {
    label: 'Мерчант',
    field: ['company_name'],
  },
  {
    label: 'ИНН',
    field: ['company_inn'],
  },
  {
    label: 'ОКЭД',
    field: ['company_oked'],
  },
  {
    label: 'Юридический адрес',
    field: ['company_legal_address'],
  },
  {
    label: 'Количество учредителей',
    field: ['company_founders_count'],
  },
  {
    label: 'Директор',
    field: ['director_name', ',', 'director_passport_series', 'director_passport_id'],
  },
  {
    label: 'Бухгалтер',
    field: ['chief_accountant_name', ',', 'chief_accountant_passport_series', 'chief_accountant_passport_id'],
  },
  {
    label: 'Контактное лицо',
    field: ['contact_name', ',', 'contact_phone', ',', 'contact_email'],
  },
  {
    label: 'Банк / РС',
    field: ['bank_name', ', МФО: ', 'bank_mfo', ',', 'bank_account'],
  }
];
