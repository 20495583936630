import { useContext, useEffect } from 'react';
import { LayoutContext } from '../core/contexts';
import { ILayout } from '../models';

export const useLayout = () => {
  const { options, setOptions } = useContext(LayoutContext);

  const handleChangeParams = (params: ILayout) => {
    setOptions(params);
  };

  useEffect(() => {
    return () => {
      setOptions(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { options, handleChangeParams };
};
