import { ComponentType, PropsWithChildren } from 'react';
import { StatusHidden, StatusHiddenProps } from './StatusHidden';

export const StatusHiddenDisabled: ComponentType<PropsWithChildren<StatusHiddenProps>> = ({
  children,
  ...props
}) => {
  return (
    <StatusHidden color="secondary" {...props}>
      {children}
    </StatusHidden>
  );
};
