import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusButtonInfo } from './StatusButtonInfo';
import { StatusButtonSuccess } from './StatusButtonSuccess';
import { StatusButtonWarning } from './StatusButtonWarning';
import { StatusButtonError } from './StatusButtonError';
import { StatusButtonDisabled } from './StatusButtonDisabled';
import { CreditStatusesEnums } from '../../core/enums';
import { CREDITS_STATUSES_LABELS } from '../../core/constants';

interface StatusButtonCreditProps {
  status: CreditStatusesEnums;
}

export const StatusButtonCredit: ComponentType<StatusButtonCreditProps> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case CreditStatusesEnums.NEW:
      return (
        <StatusButtonInfo>{t(CREDITS_STATUSES_LABELS[CreditStatusesEnums.NEW])}</StatusButtonInfo>
      );
    case CreditStatusesEnums.CANCELED:
      return (
        <StatusButtonError>
          {t(CREDITS_STATUSES_LABELS[CreditStatusesEnums.CANCELED])}
        </StatusButtonError>
      );
    case CreditStatusesEnums.IN_PROGRESS:
      return (
        <StatusButtonWarning>
          {t(CREDITS_STATUSES_LABELS[CreditStatusesEnums.IN_PROGRESS])}
        </StatusButtonWarning>
      );
    case CreditStatusesEnums.COMPLETED:
      return (
        <StatusButtonSuccess>
          {t(CREDITS_STATUSES_LABELS[CreditStatusesEnums.COMPLETED])}
        </StatusButtonSuccess>
      );
    case CreditStatusesEnums.INSURANCE:
      return (
        <StatusButtonInfo>
          {t(CREDITS_STATUSES_LABELS[CreditStatusesEnums.INSURANCE])}
        </StatusButtonInfo>
      );
    default:
      return <StatusButtonDisabled>{status}</StatusButtonDisabled>;
  }
};
