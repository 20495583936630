import { ComponentType, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@mui/material';
import { useLayout } from '../../../hooks';
import { useAuth } from '../../../hooks';

export const LogoutPage: ComponentType = () => {
  const { t } = useTranslation();
  const { handleLogout } = useAuth();
  const { handleChangeParams } = useLayout();

  const title = t('Выход');

  useEffect(() => {
    handleChangeParams({ title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box width={{ xs: 320, sm: 400, md: 480 }} m="auto">
        <Typography variant="h1" textAlign="center" mb={2}>
          {title}
        </Typography>
      </Box>
    </>
  );
};
