import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../core/utils/rtk.utils';
import { dynamicRoute } from '../core/utils/routes.utils';
import { IUnderwriterList, IUnderwriterDetails } from '../models';
import { ENDPOINTS } from '../core/constants';

export const applicationsUnderwriter = createApi({
  reducerPath: 'applicationsUnderwriter',
  baseQuery: rtkBaseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    fetchAllApplicationsUnderwriter: build.query<IUnderwriterList, Record<string, any>>({
      query: (params = {}) => ({
        url: ENDPOINTS.APPLICATIONS_UNDERWRITER.FETCH_ALL,
        params,
      }),
    }),
    fetchOneApplicationUnderwriter: build.query<IUnderwriterDetails, string | number | null>({
      query: (id) => ({
        url: dynamicRoute(ENDPOINTS.APPLICATIONS_UNDERWRITER.FETCH_ONE, { id }),
      }),
    }),
  }),
});

export const { useFetchAllApplicationsUnderwriterQuery, useFetchOneApplicationUnderwriterQuery } =
  applicationsUnderwriter;
