import { ComponentType, PropsWithChildren } from 'react';
import { Button } from '@mui/material';

export interface StatusHiddenProps {
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export const StatusHidden: ComponentType<PropsWithChildren<StatusHiddenProps>> = ({
  color,
  children,
}) => {
  return (
    <Button
      variant="contained"
      color={color}
      component="div"
      size="small"
      sx={{ cursor: 'text', height: 'auto', textAlign: 'center' }}
      fullWidth
    >
      {children}
    </Button>
  );
};
