import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Pagination,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { map } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../components/data-table';
import { SelectPerPage } from '../../../components/inputs';
import { LoaderPage } from '../../../components/loader';
import { REPORT_TYPES } from '../../../core/constants/report-types.constants';
import { formikTextFieldProps } from '../../../core/utils/form.utils';
import { formatDateToString } from '../../../core/utils/format.utils';
import { useLayout, useResources } from '../../../hooks';
import { useGenerateReportMutation, useGetReportsQuery } from '../../../services/ReportsApi';
import { REPORTS_COLUMNS } from '../../../sources/columns/reports.columns';

export const ReportsPage: ComponentType = () => {
  const { t } = useTranslation();
  const { handleChangeParams } = useLayout();
  const {
    dataList,
    currentPage,
    perPage,
    pagesCount,
    orderBy,
    orderDirection,
    isFetching,
    isLoading,
    handleChangeLimit,
    handleChangePage,
    handleChangeSort,
  } = useResources(useGetReportsQuery);

  // const { data, isFetching } = useGetReportsQuery({});
  const [generateReport, { isLoading: isGenerating }] = useGenerateReportMutation();

  const title = t('Отчеты');

  useEffect(() => {
    handleChangeParams({ title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      type: 'all',
      date_from: null,
      date_to: null,
    },
    onSubmit: (values) => {
      console.log('values', values);
      generateReport(values);
    },
  });

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Card>
        <Stack component={CardContent} gap={2}>
          <Box
            display="flex"
            alignItems="flex-end"
            gap={3}
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Stack>
              <TextField
                label="Вид отчета"
                size="small"
                select
                {...formikTextFieldProps(formik, 'type')}
              >
                {map(REPORT_TYPES, (type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack>
              {/* <Typography color="primary">Укажите период отчета</Typography> */}
              <Box display="flex" gap={2}>
                <DatePicker
                  label={t('Период отчета, от')}
                  value={formik.values.date_from ? new Date(formik.values.date_from) : null}
                  onChange={(value) =>
                    formik.setFieldValue('date_from', value ? formatDateToString(value) : null)
                  }
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                  sx={{ width: 180 }}
                  maxDate={new Date()}
                />
                <DatePicker
                  label={t('Период отчета, до')}
                  value={formik.values.date_to ? new Date(formik.values.date_to) : null}
                  onChange={(value) =>
                    formik.setFieldValue('date_to', value ? formatDateToString(value) : null)
                  }
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                  sx={{ width: 180 }}
                  maxDate={new Date()}
                />
              </Box>
            </Stack>
            <Button variant="outlined" size="small" type="submit" disabled={isGenerating}>
              Сформировать
            </Button>
            {/* <Button variant="contained" size="small" startIcon={<Download />}>
              Скачать Excel
            </Button> */}
          </Box>

          <DataTable
            data={dataList}
            isLoading={isFetching}
            columns={REPORTS_COLUMNS}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onOrder={handleChangeSort}
          />

          <Box
            component={Stack}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={1.5}
            p={1.5}
            borderTop="1px solid #E5E9EB"
          >
            <SelectPerPage onChange={handleChangeLimit} value={perPage} />
            <Pagination onChange={handleChangePage} page={currentPage} count={pagesCount} />
          </Box>
        </Stack>
      </Card>
    </>
  );
};
