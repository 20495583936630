import { SelectUserRole } from '../../components/inputs/select-user-role';
import { IColumn } from '../../models';

export const USERS_COLUMNS: IColumn[] = [
  {
    dataKey: 'fullname',
    label: 'ФИО пользователя',
    renderItem: ({ first_name, last_name }) => [first_name, last_name].join(' '),
  },
  {
    dataKey: 'email',
    label: 'Email',
    renderItem: (user) => user.email,
  },
  // {
  //   dataKey: 'password',
  //   label: 'Пароль',
  //   renderItem: (user) => <InputPassword value={user.password} />,
  //   width: 250,
  // },
  {
    dataKey: 'loan_amount',
    label: 'Роль',
    renderItem: (user) => <SelectUserRole userId={user.id} value={user.role_code} />,
  },
];
