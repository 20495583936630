import { cloneDeep, findIndex, get, map, trim } from 'lodash';

export const dataRow = (data: any, rows: any[]) => {
  return map(rows, (col) => {
    const field = get(col, 'field', []);
    const value = map(field, (f) => {
      let val = trim(get(data, `data.${f}`, ''));
      if (val === '') {
        val = f + ' ';
      } else {
        val = '' + val;
      }

      return val;
    }).join('');

    return {
      label: col.label,
      value: col.renderItem ? col.renderItem(value) : value,
    };
  });
};

export const findIndexesAndRemove = (keys: any[], field: string, arr: any[]) => {
  const newArr = cloneDeep(arr);
  for (const key of keys) {
    const idx = findIndex(arr, (x) => x[field] === key);
    if (idx !== -1) {
      newArr[idx] = false;
    }
  }
  return (newArr || []).filter(Boolean);
};
