import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusButtonSuccess } from './StatusButtonSuccess';
import { StatusButtonError } from './StatusButtonError';
import { StatusButtonDisabled } from './StatusButtonDisabled';
import { ScoringStatusesEnums } from '../../core/enums';
import { SCORING_STATUSES_LABELS } from '../../core/constants';

interface StatusButtonScoringProps {
  status: ScoringStatusesEnums;
}

export const StatusButtonScoring: ComponentType<StatusButtonScoringProps> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case ScoringStatusesEnums.BAD:
      return (
        <StatusButtonError>
          {t(SCORING_STATUSES_LABELS[ScoringStatusesEnums.BAD])}
        </StatusButtonError>
      );
    case ScoringStatusesEnums.GOOD:
      return (
        <StatusButtonSuccess>
          {t(SCORING_STATUSES_LABELS[ScoringStatusesEnums.GOOD])}
        </StatusButtonSuccess>
      );
    default:
      return <StatusButtonDisabled>{status}</StatusButtonDisabled>;
  }
};
