import { Typography } from '@mui/material';
import { trim } from 'lodash';
import { Link } from 'react-router-dom';
import { SumCurrency } from '../../components/sum-currency';
import { ROUTES } from '../../core/constants';
import { separateNumber } from '../../core/utils/format.utils';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { IRow } from '../../models';

export const CREDITS_ROWS: IRow[] = [
  // {
  //   label: 'Желаемая сумма кредита',
  //   field: ['loan_amount_desired'],
  //   renderItem: (value: any) => <SumCurrency sum={value} currency="сум" />,
  // },
  {
    label: 'Заявка',
    field: ['application_id'],
    renderItem: (value: number) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT, {
          id: trim(value + ''),
        })}
        variant="body2"
        color="primary.main"
      >
        {trim(value + '')}
      </Typography>
    ),
  },
  {
    label: 'Сумма кредита',
    field: ['amount'],
    renderItem: (value: number | string) => <SumCurrency sum={value} currency="сум" />,
  },
  {
    label: 'Срок',
    field: ['term'],
  },
  {
    label: 'Годовая ставка',
    field: ['percentage_annual'],
    renderItem: (value: any) => {
      return (value ? separateNumber(Number(value)) : 0) + '%';
    },
  },
  {
    label: 'Месячная ставка',
    field: ['percentage_monthly'],
    renderItem: (value: any) => {
      return (value ? separateNumber(Number(value)) : 0) + '%';
    },
  },
];
