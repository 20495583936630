import { styled } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

interface SidebarMenuItemProps extends LinkProps {
  isActive?: boolean;
}

const SidebarMenuItemComponent = ({ isActive, ...props }: SidebarMenuItemProps) => (
  <Link {...props} />
);

export const SidebarMenuItem = styled(SidebarMenuItemComponent)(({ theme, isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  fontSize: 14,
  fontWeight: 600,
  //transition: 'all 200ms ease-in 0ms',
  borderRadius: theme.shape.borderRadius,
  color: isActive ? theme.palette.common.white + '!important' : theme.palette.text.secondary,
  background: isActive ? theme.palette.primary.main : theme.palette.common.white,
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  '& span, & svg': {
    display: 'block',
  },
}));
