import { ApplicationStatusesEnums } from '../enums';

export const APPLICATIONS_STATUSES_LABELS: Record<ApplicationStatusesEnums, string> = {
  [ApplicationStatusesEnums.NEW]: 'Новая заявка',
  [ApplicationStatusesEnums.PRE_SCORING_OK]: 'Прескоринг одобрен',
  [ApplicationStatusesEnums.PRE_SCORING_NOK]: 'Прескоринг не одобрен',
  [ApplicationStatusesEnums.UNDERWRITER]: 'На рассмотрении',
  [ApplicationStatusesEnums.UNDERWRITER_OK]: 'Кредитный эксперт одобрил',
  [ApplicationStatusesEnums.UNDERWRITER_NOK]: 'Кредитный эксперт не одобрил',
  [ApplicationStatusesEnums.LOAN_OFFICER]: 'Кредит одобрен',
  [ApplicationStatusesEnums.LOAN]: 'Выдали кредит',
  [ApplicationStatusesEnums.PROBLEM]: 'Проблемный кредит',
  [ApplicationStatusesEnums.INSURANCE]: 'Страховка оплатила',
  [ApplicationStatusesEnums.COMPLETED]: 'Завершённый кредит',
  [ApplicationStatusesEnums.CANCELLED]: 'Заявка отменена',
};
