import { IMenuItem } from '../../models';
import { ROUTES } from '../constants';

export const MERCHANTS_MENU: IMenuItem[] = [
  {
    label: 'Сведения о компании',
    path: ROUTES.CABINET.MERCHANTS.DETAILS.ROOT,
  },
  {
    label: 'Информация по магазину',
    path: ROUTES.CABINET.MERCHANTS.SHOP,
  },
];
