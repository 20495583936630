import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusButtonInfo } from './StatusButtonInfo';
import { StatusButtonSuccess } from './StatusButtonSuccess';
import { StatusButtonWarning } from './StatusButtonWarning';
import { StatusButtonError } from './StatusButtonError';
import { StatusButtonDisabled } from './StatusButtonDisabled';
import { CreditScheduleStatusesEnums } from '../../core/enums';
import { CREDITS_SCHEDULE_STATUSES_LABELS } from '../../core/constants';

interface StatusButtonCreditScheduleProps {
  status: CreditScheduleStatusesEnums;
}

export const StatusButtonCreditSchedule: ComponentType<StatusButtonCreditScheduleProps> = ({
  status,
}) => {
  const { t } = useTranslation();

  switch (status) {
    case CreditScheduleStatusesEnums.NEW:
      return (
        <StatusButtonInfo>
          {t(CREDITS_SCHEDULE_STATUSES_LABELS[CreditScheduleStatusesEnums.NEW])}
        </StatusButtonInfo>
      );
    case CreditScheduleStatusesEnums.PAID:
      return (
        <StatusButtonSuccess>
          {t(CREDITS_SCHEDULE_STATUSES_LABELS[CreditScheduleStatusesEnums.PAID])}
        </StatusButtonSuccess>
      );
    case CreditScheduleStatusesEnums.PAID_BEFORE:
      return (
        <StatusButtonInfo>
          {t(CREDITS_SCHEDULE_STATUSES_LABELS[CreditScheduleStatusesEnums.PAID_BEFORE])}
        </StatusButtonInfo>
      );
    case CreditScheduleStatusesEnums.PAID_LATER:
      return (
        <StatusButtonInfo>
          {t(CREDITS_SCHEDULE_STATUSES_LABELS[CreditScheduleStatusesEnums.PAID_LATER])}
        </StatusButtonInfo>
      );
    case CreditScheduleStatusesEnums.PAID_PARTIALLY:
      return (
        <StatusButtonWarning>
          {t(CREDITS_SCHEDULE_STATUSES_LABELS[CreditScheduleStatusesEnums.PAID_PARTIALLY])}
        </StatusButtonWarning>
      );
    case CreditScheduleStatusesEnums.NOT_PAID:
      return (
        <StatusButtonError>
          {t(CREDITS_SCHEDULE_STATUSES_LABELS[CreditScheduleStatusesEnums.NOT_PAID])}
        </StatusButtonError>
      );
    default:
      return <StatusButtonDisabled>{status}</StatusButtonDisabled>;
  }
};
