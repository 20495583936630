import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import {ApplicationHiddenTypesEnums} from "../../core/enums/application-hidden-types.enums";
import {APPLICATIONS_HIDDEN_TYPES_LABELS} from "../../core/constants/applications-hidden-types-labels.constants";
import { StatusHiddenYes } from './StatusHiddenYes';
import { StatusHiddenNo } from './StatusHiddenNo';
import {StatusHiddenDisabled} from "./StatusHiddenDisabled";

interface StatusHiddenApplicationProps {
  status: ApplicationHiddenTypesEnums;
}

export const StatusHiddenApplication: ComponentType<StatusHiddenApplicationProps> = ({
  status,
}) => {
  const { t } = useTranslation();

  switch (status) {
    case ApplicationHiddenTypesEnums.YES:
      return (
          <StatusHiddenYes>
            {t(APPLICATIONS_HIDDEN_TYPES_LABELS[ApplicationHiddenTypesEnums.YES])}
          </StatusHiddenYes>
      );
    case ApplicationHiddenTypesEnums.NO:
      return (
          <StatusHiddenNo>
            {t(APPLICATIONS_HIDDEN_TYPES_LABELS[ApplicationHiddenTypesEnums.NO])}
          </StatusHiddenNo>
      );
    default:
      return <StatusHiddenDisabled>{status}</StatusHiddenDisabled>;
  }
};
