import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../../hooks';
import { usePatchOneUserMutation } from '../../../services/UsersApi';

export enum RolesEnum {
  admin = 'Администратор',
  seller = 'Продавец',
  underwriter = 'Андеррайтер',
  loan_officer = 'Кредитный эксперт',
  guest = 'Гость',
}

type Props = TextFieldProps & {
  value: keyof typeof RolesEnum;
  userId: number;
};

export const SelectUserRole: ComponentType<Props> = ({ value, userId }) => {
  const { t } = useTranslation();
  const [role, setRole] = useState<keyof typeof RolesEnum>(value);
  const { showMessage } = useSnackbar();

  const [update, { isLoading }] = usePatchOneUserMutation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    update({ id: userId, role_code: e.target.value })
      .unwrap()
      .then(() => {
        setRole(e.target.value as keyof typeof RolesEnum);
        showMessage('Роль пользователя успешно изменена', { severity: 'success' });
      })
      .catch((error) => {
        showMessage(error.data.detail, { severity: 'error' });
      });
  };

  return (
    <TextField
      value={role}
      select
      size="small"
      fullWidth
      disabled={isLoading}
      onChange={handleChange}
    >
      {Object.keys(RolesEnum).map((key) => (
        <MenuItem value={key} key={key}>
          {t(key, RolesEnum[key as keyof typeof RolesEnum])}
        </MenuItem>
      ))}
    </TextField>
  );
};
