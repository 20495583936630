import { Palette } from '@mui/material/styles/createPalette';
import { darken, lighten } from '@mui/system';

export const lightPalette: Partial<Palette> = {
  mode: 'light',
  common: {
    white: '#fff',
    black: '#000',
  },
  primary: {
    main: '#0E73F6',
    light: lighten('#0E73F6', 0.05),
    dark: darken('#0E73F6', 0.05),
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#F4F6FA',
    light: lighten('#F4F6FA', 0.05),
    dark: darken('#F4F6FA', 0.05),
    contrastText: '#303940',
  },
  info: {
    main: '#D7EDFF',
    light: lighten('#D7EDFF', 0.05),
    dark: darken('#D7EDFF', 0.05),
    contrastText: '#0452C8',
  },
  success: {
    main: '#BBFBD0',
    light: lighten('#BBFBD0', 0.05),
    dark: darken('#BBFBD0', 0.05),
    contrastText: '#119C2B',
  },
  error: {
    main: '#fdd7d7',
    light: lighten('#fdd7d7', 0.05),
    dark: darken('#fdd7d7', 0.05),
    contrastText: '#F2271C',
  },
  warning: {
    main: '#fbe9ca',
    light: lighten('#fbe9ca', 0.05),
    dark: darken('#fbe9ca', 0.05),
    contrastText: '#ff2300',
  },
  text: {
    primary: '#303940',
    secondary: '#6E8BB7',
    disabled: '#C0C0C0',
  },
  background: {
    paper: '#FFFFFF',
    default: '#FAFAFA',
  },
  grey: {
    50: '#FAFAFA',
    100: '#E5E9F0',
    200: '#D0D4E0',
    300: '#B9C0D1',
    400: '#A7A9B9',
    500: '#8E8E93',
    600: '#7D7D7D',
    700: '#636363',
    800: '#4D4D4D',
    900: '#363636',
    A100: '#F2F6FE',
    A200: '#D0D4E0',
    A400: '#A7A9B9',
    A700: '#636363',
  },
};
