import { DocumentTypesEnums } from '../enums';

export const DOCS_TYPES_LABELS: Record<DocumentTypesEnums | any, string> = {
  [DocumentTypesEnums.STATEMENT]: 'Заявление',
  [DocumentTypesEnums.LOAN_AGREEMENT]: 'Кредитный договор',
  [DocumentTypesEnums.CONCLUSION]: 'Заключение кредитного эксперта',
  [DocumentTypesEnums.SCHEDULE]: 'График погащения',
  [DocumentTypesEnums.INSURANCE]: 'Страховка',
  [DocumentTypesEnums.CERTIFICATE]: 'Свидетельство о регистрации',
  [DocumentTypesEnums.CERTIFICATE_NDS]: 'Свидетельство о регистрации НДС',
  [DocumentTypesEnums.CHARTER]: 'Устав компании',
  [DocumentTypesEnums.AGREEMENT_FOUNDERS]: 'Учредительный договор',
  [DocumentTypesEnums.PASSPORT]: 'Паспорт / ID карта директора',
  [DocumentTypesEnums.ORDER_DIRECTOR]: 'Приказ о назначении директора',
  [DocumentTypesEnums.PASSPORT_FOUNDERS]: 'Паспорта / ID карты учредителей',
  [DocumentTypesEnums.FORM_1]: 'Бухгалтерский баланс (форма №1)',
  [DocumentTypesEnums.FORM_2]: 'Финансовые результаты (форма №2)',
  [DocumentTypesEnums.KATM]: 'КАТМ',
  [DocumentTypesEnums.STATEMENT_SIGNED]: 'Заявление подписанное',
  [DocumentTypesEnums.LOAN_AGREEMENT_SIGNED]: 'Кредитный договор подписанный',
  [DocumentTypesEnums.CONCLUSION_COMPLETED]: 'Заключение андеррайтера дополненное',
  [DocumentTypesEnums.CONCLUSION_LOAN_EXPERT]: 'Заключение кредитного эксперта',
  [DocumentTypesEnums.BANK_STATEMENT_USED_MONEY]: 'Выписка банка об использовании кредитных средств',
};
