import { Box, Card, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLoanDynamicsQuery } from '../../../services/DashboardApi';

type DataType = {
  label: string;
  value: number;
};

const BarChart: FC = () => {
  const { t } = useTranslation();
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());

  const { data: response } = useGetLoanDynamicsQuery({ year });

  if (!response) return null;

  const { items, params, title } = response.data;

  const data: DataType[] = Object.values(items);
  const max = Math.max(...data.map((item) => item.value));

  const getPercents = (value: number) => {
    return ((value / max) * 100).toFixed(0);
  };

  return (
    <Card
      sx={{ border: 1, borderColor: '#E5E9EB', pt: 1.5, px: 3, pb: 2, height: 270, width: 720 }}
    >
      <Stack height={1}>
        <Box display="flex" mb="auto">
          <Stack mr="auto" gap={0.625}>
            <Typography fontSize={20} fontWeight={600} color="primary">
              {title}
            </Typography>
            <Typography fontSize={16} color="#9A9EA5">
              {params.year.items[year]}
            </Typography>
          </Stack>

          <TextField
            select
            value={params.year.value}
            size="small"
            onChange={(e) => setYear(e.target.value)}
          >
            {Object.entries(params.year.items).map(([key, value]: any) => (
              <MenuItem key={key} value={key}>
                {params.year.label} / {key}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Grid container spacing={0.625} pt={3}>
          {data.map((item) => (
            <Grid item xs={1} key={item.label}>
              <Stack textAlign="center" gap={0.625}>
                <Box
                  display="flex"
                  flexGrow={1}
                  height={100}
                  justifyContent="center"
                  alignItems="flex-end"
                  gap={0.625}
                >
                  <Box
                    height={getPercents(item.value) + '%'}
                    bgcolor="info.main"
                    position="relative"
                    minHeight={4}
                    borderRadius={0.75}
                    width={1}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                      }}
                    >
                      {item.value}
                    </Box>
                  </Box>
                </Box>
                <Typography fontSize={14}>{t(item.label)}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Card>
  );
};

export default BarChart;
