import { ComponentType, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import { Box, Button, Stack, TextField } from '@mui/material';
import { IconChevronRight } from '../../icons';
import { useSnackbar } from '../../../hooks';
import { useAuth } from '../../../hooks';
import { FIELD_LOGIN, FIELD_PASSWORD, ROUTES } from '../../../core/constants';

export const AuthLogin: ComponentType = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const { isAuthorized, formik, hasError, getErrorMessage, isLoading, isError, data, error } =
    useAuth();

  useEffect(() => {
    if (isAuthorized) navigate(ROUTES.CABINET.ROOT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      const message = get(data, 'message');
      showMessage(message, { severity: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError && !isEmpty(error)) {
      const defaultErrorMessage = get(error, 'data.message', t('Ошибка'));
      const message = get(error, 'data.data.error', defaultErrorMessage);
      showMessage(message, { severity: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError]);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      action={ROUTES.HOME}
      method="POST"
      width="100%"
    >
      <Stack spacing={2} mb={3}>
        <TextField
          {...formik.getFieldProps(FIELD_LOGIN)}
          error={hasError(FIELD_LOGIN)}
          helperText={getErrorMessage(FIELD_LOGIN)}
          label={t('Логин')}
          disabled={isLoading}
        />
        <TextField
          {...formik.getFieldProps(FIELD_PASSWORD)}
          error={hasError(FIELD_PASSWORD)}
          helperText={getErrorMessage(FIELD_PASSWORD)}
          label={t('Пароль')}
          type="password"
          disabled={isLoading}
        />
      </Stack>
      <Box textAlign="right">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<IconChevronRight />}
          disabled={isLoading}
        >
          {t('Войти')}
        </Button>
      </Box>
    </Box>
  );
};
