import { ComponentType } from 'react';
import { isEmpty, map, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  BoxProps,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableChart } from '@mui/icons-material';
import { Loader } from '../loader';
import { Annunciator } from '../annunciator';
import { IconSort } from '../icons';
import { StyledTable, StyledTableCell, StyledTableRow } from './styled';
import { IColumn } from '../../models';

type OrderDirection = 'asc' | 'desc' | null;

interface DataTableProps extends BoxProps {
  data: any[];
  columns?: IColumn[];
  isLoading?: boolean;
  orderBy?: string | null;
  orderDirection?: OrderDirection;
  onOrder?: (dataKey: string, direction: OrderDirection) => void;
}

const getOrderDirection = (direction: OrderDirection) => {
  switch (direction) {
    case 'asc':
      return 'desc';
    case 'desc':
      return null;
    default:
      return 'asc';
  }
};

export const DataTable: ComponentType<DataTableProps> = ({
  className,
  data = [],
  columns,
  isLoading = false,
  orderBy,
  orderDirection,
  onOrder,
  ...props
}) => {
  const { t } = useTranslation();

  const handleSort = (dataKey: string) => {
    const direction =
      orderBy === dataKey && orderDirection ? getOrderDirection(orderDirection) : 'asc';

    if (onOrder) onOrder(dataKey, direction);
  };

  const tablePlaceholder = (status: string) => {
    let colSpan = columns?.length ? columns?.length : 1;

    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={colSpan}>
            {status === 'loading' && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Loader />
              </Box>
            )}
            {status === 'empty' && <Annunciator title={t('По вашему запросу ничего не найдено')} />}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const tableHead = () => {
    if (isEmpty(columns)) return null;

    let cols = map(columns, ({ dataKey, label, width, sortable }, idx) => {
      return (
        <StyledTableCell width={width} key={idx}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            {label ? (
              <Typography variant="body2" fontWeight={600}>
                {t(label)}
              </Typography>
            ) : (
              <Box m="auto">
                <TableChart color="primary" fontSize="small" />
              </Box>
            )}
            {sortable && (
              <IconButton
                onClick={() => handleSort(dataKey)}
                size="small"
                color="inherit"
                aria-label={t('sort')}
              >
                <IconSort />
              </IconButton>
            )}
          </Stack>
        </StyledTableCell>
      );
    });

    return (
      <TableHead>
        <TableRow>{cols}</TableRow>
      </TableHead>
    );
  };

  const tableBody = () => {
    if (isEmpty(columns) || !columns?.length) return null;

    const rows = map(data, (item, idx) => {
      const cols = map(columns, ({ dataKey, renderItem }, colx) => {
        if (renderItem) {
          return <StyledTableCell key={colx}>{renderItem(item, idx)}</StyledTableCell>;
        }
        return <StyledTableCell key={colx}>{get(item, dataKey, null)}</StyledTableCell>;
      });

      return <StyledTableRow key={idx}>{cols}</StyledTableRow>;
    });

    return <TableBody>{rows}</TableBody>;
  };

  if (isLoading) {
    return (
      <Table>
        {tableHead()}
        {tablePlaceholder('loading')}
      </Table>
    );
  }

  if (isEmpty(data)) {
    return (
      <Table>
        {tableHead()}
        {tablePlaceholder('empty')}
      </Table>
    );
  }

  return (
    <Box {...props}>
      <StyledTable>
        {tableHead()}
        {tableBody()}
      </StyledTable>
    </Box>
  );
};
