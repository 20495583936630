import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import Chart from 'react-google-charts';

interface Props {
  label: string;
  data: any;
}

const colors: any = {
  pre_scoring_nok: { color: '#E07A5F', label: 'Прескоринг не одобрен' },
  pre_scoring_ok: { color: '#00ec7a', label: 'Прескоринг одобрен' },
  cancelled: { color: '#F2CC8F', label: 'Отменен' },
  loan: { color: '#50dc98', label: 'Выдали кредит' },
  completed: { color: '#6abd61', label: 'Завершен' },
  underwriter_nok: { color: '#CBAACB', label: 'Кредитный эксперт не одобрил' },
  underwriter: { color: '#6C63FF', label: 'Андеррайтер рассматривает документы' },
  loan_officer: { color: '#9DAAF2', label: 'Кредитный эксперт' },
  new: { color: '#85c1ff', label: 'Новый кредит' },
};

const DonutChart: FC<Props> = ({ data, label }) => {
  const items = [
    ...Object.values(data.items).map((item: any) => ({
      ...item,
      key: item.label,
      ...colors[item.label],
    })),
  ];

  const options = {
    pieHole: 0.7,
    legend: 'none',
    pieSliceText: 'none',
    tooltip: { trigger: 'none' },
    enableInteractivity: false,
    slices: items.reduce((acc: any, item: any, index: number) => {
      acc[index] = { color: item.color };
      return acc;
    }, {}),
  };

  return (
    <Stack gap={1.25}>
      <Box
        sx={{
          position: 'relative',
          width: 1,
          height: 150,
          overflow: 'hidden',
          '& svg': { transform: 'scale(1.6)' },
        }}
      >
        <Chart
          chartType="PieChart"
          width="150px"
          height="150px"
          data={[
            ['Заявки', 'Количество'],
            ...items.map((item) => [item.key, item.value < 2 ? 2 : item.value]),
          ]}
          options={options}
        />
        <Stack
          sx={{
            position: 'absolute',
            zIndex: 10,
            width: 150,
            height: 150,
            top: 0,
            left: 0,
            padding: 3.5,
            paddingTop: 4.5,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: 12, lineHeight: 1, color: '#9A9EA5' }}>{label}</Typography>
          <Typography sx={{ fontSize: 32, lineHeight: 1 }}>{data.count}</Typography>
        </Stack>
      </Box>
      <Stack gap={1.25}>
        {items.map((item: any, index: number) => (
          <Box display="flex" alignItems="center" gap={1.25} key={index}>
            <Box width={20} height={20} bgcolor={item.color} borderRadius={1} />
            <Typography fontSize={12} fontWeight={500}>
              {item.label}{' '}
              <Typography component="span" fontSize={12} color="#9A9EA5">
                {item.value} ({item.percentage}%)
              </Typography>
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default DonutChart;
