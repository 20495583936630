import { ComponentType } from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';

interface LoaderProps extends BoxProps {
  size?: number;
}

export const Loader: ComponentType<LoaderProps> = ({ size = 64, minHeight = '100%', ...props }) => {
  return (
    <Box display="flex" minHeight={minHeight} alignItems="center" {...props}>
      <CircularProgress color="info" size={size} />
    </Box>
  );
};
