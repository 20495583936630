import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusButtonWarning } from './StatusButtonWarning';
import { StatusButtonSuccess } from './StatusButtonSuccess';
import { StatusButtonError } from './StatusButtonError';
import { StatusButtonInfo } from './StatusButtonInfo';
import { StatusButtonDisabled } from './StatusButtonDisabled';
import { StatusButtonCancelled } from './StatusButtonCancelled';
import { StatusButtonLoan } from './StatusButtonLoan';
import { ApplicationStatusesEnums } from '../../core/enums';
import { APPLICATIONS_STATUSES_LABELS } from '../../core/constants';

interface StatusButtonApplicationProps {
  status: ApplicationStatusesEnums;
}

export const StatusButtonApplication: ComponentType<StatusButtonApplicationProps> = ({
  status,
}) => {
  const { t } = useTranslation();

  switch (status) {
    case ApplicationStatusesEnums.NEW:
      return (
        <StatusButtonInfo>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.NEW])}
        </StatusButtonInfo>
      );
    case ApplicationStatusesEnums.PRE_SCORING_OK:
      return (
        <StatusButtonSuccess>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.PRE_SCORING_OK])}
        </StatusButtonSuccess>
      );
    case ApplicationStatusesEnums.PRE_SCORING_NOK:
      return (
        <StatusButtonError>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.PRE_SCORING_NOK])}
        </StatusButtonError>
      );
    case ApplicationStatusesEnums.UNDERWRITER:
      return (
        <StatusButtonWarning>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.UNDERWRITER])}
        </StatusButtonWarning>
      );
    case ApplicationStatusesEnums.UNDERWRITER_OK:
      return (
        <StatusButtonSuccess>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.UNDERWRITER_OK])}
        </StatusButtonSuccess>
      );
    case ApplicationStatusesEnums.UNDERWRITER_NOK:
      return (
        <StatusButtonError>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.UNDERWRITER_NOK])}
        </StatusButtonError>
      );
    case ApplicationStatusesEnums.LOAN_OFFICER:
      return (
        <StatusButtonInfo>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.LOAN_OFFICER])}
        </StatusButtonInfo>
      );
    case ApplicationStatusesEnums.LOAN:
      return (
        <StatusButtonLoan>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.LOAN])}
        </StatusButtonLoan>
      );
    case ApplicationStatusesEnums.PROBLEM:
      return (
        <StatusButtonError>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.PROBLEM])}
        </StatusButtonError>
      );
    case ApplicationStatusesEnums.INSURANCE:
      return (
        <StatusButtonInfo>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.INSURANCE])}
        </StatusButtonInfo>
      );
    case ApplicationStatusesEnums.COMPLETED:
      return (
        <StatusButtonSuccess>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.COMPLETED])}
        </StatusButtonSuccess>
      );
    case ApplicationStatusesEnums.CANCELLED:
      return (
        <StatusButtonCancelled>
          {t(APPLICATIONS_STATUSES_LABELS[ApplicationStatusesEnums.CANCELLED])}
        </StatusButtonCancelled>
      );
    default:
      return <StatusButtonDisabled>{status}</StatusButtonDisabled>;
  }
};
