import { Button, IconButton, TableBody } from '@mui/material';
import { find, get, map } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { DOCS_TYPES_LABELS, ROUTES } from '../../core/constants';
import { DocumentTypesEnums, FileTypesEnums } from '../../core/enums';
import { dynamicRoute } from '../../core/utils/routes.utils';
import {
  useAttachment,
  useChangeUnderwriterStatus,
  useManagers,
  useSnackbar,
  useTemplate,
} from '../../hooks';
import {
  useCopyDocumentsMutation,
  useFetchOneApplicationQuery,
} from '../../services/ApplicationsApi';
import { StyledTable, StyledTableCell, StyledTableRow } from '../data-table/styled';
import { FileGenerator, FileUploader, SelectUnderwriterStatus } from '../inputs';
import { StatusButtonUnderwriter } from '../status-button';

const ATTACHED_DOCUMENTS = [
  DocumentTypesEnums.CERTIFICATE,
  DocumentTypesEnums.CERTIFICATE_NDS,
  DocumentTypesEnums.CHARTER,
  DocumentTypesEnums.AGREEMENT_FOUNDERS,
  DocumentTypesEnums.PASSPORT_FOUNDERS,
  DocumentTypesEnums.PASSPORT,
  DocumentTypesEnums.ORDER_DIRECTOR,
  DocumentTypesEnums.FORM_1,
  DocumentTypesEnums.FORM_2,
];

const UPLOADABLE_DOCUMENTS = [
  DocumentTypesEnums.KATM,
  DocumentTypesEnums.INSURANCE,
  DocumentTypesEnums.STATEMENT_SIGNED,
  // DocumentTypesEnums.LOAN_AGREEMENT_SIGNED,
  DocumentTypesEnums.CONCLUSION_COMPLETED,
  DocumentTypesEnums.BANK_STATEMENT_USED_MONEY,
];

const GENERATABLE_DOCUMENTS = [
  DocumentTypesEnums.STATEMENT,
  DocumentTypesEnums.LOAN_AGREEMENT,
  DocumentTypesEnums.CONCLUSION,
  // DocumentTypesEnums.SCHEDULE,
];

interface Props {}

export const ApplicationDetailsDocs: FC<Props> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const id = get(params, 'id', null);
  const { data, refetch } = useFetchOneApplicationQuery(id);

  const { showMessage } = useSnackbar();
  const { isManager } = useManagers();
  const [copy, { isLoading }] = useCopyDocumentsMutation();

  const documentsByEntity = (entities: string[]) => {
    return map(entities, (doc) => {
      const attachment = find(attachments, { document_type: doc });
      return {
        docType: doc,
        attachment: !!attachment ? attachment : null,
      };
    });
  };
  const attachments = get(data, 'data.attachments.items', []);
  const isShowUploader = get(data, 'data.is_show_uploader', false);
  // const status = get(data, 'data.status');
  const scoringId = get(data, 'data.scoring_result.id');
  const underwriterStatus = get(data, 'data.scoring_result.status_underwriter');
  const loan = get(data, 'data.loan', []);

  const { handleAttach } = useAttachment({ id, refetch });
  const { handleGenerate } = useTemplate({ id, refetch });

  // const { handleUnderwriterOk, handleUnderwriterNok, handleLoanOfficer, handleLoan } =
  //   useChangeApplicationStatus({
  //     id,
  //     refetch,
  //   });
  const { handleChange: handleChangeUnderwriterStatus } = useChangeUnderwriterStatus({
    scoringId,
    refetch,
  });

  // const isLoanOfficerAvailable = status === ApplicationStatusesEnums.UNDERWRITER_OK && isManager;
  // const isLoanAvailable = status === ApplicationStatusesEnums.LOAN_OFFICER && isManager;
  const isChangeUnderwriterStatusVisible = !!underwriterStatus && isManager;

  // const isUnderwriterAvailable =
  //   (status === ApplicationStatusesEnums.UNDERWRITER ||
  //     status === ApplicationStatusesEnums.PRE_SCORING_OK) &&
  //   isManager;

  const handleGetFiles = () => {
    copy({ id })
      .unwrap()
      .then((res) => {
        showMessage(res.message, { severity: 'success' });
      })
      .catch(() => {
        showMessage(t('Ошибка копирования файлов'), { severity: 'error' });
      });
  };

  return (
    <div>
      <StyledTable>
        <TableBody>
          {/* {map(rows, (row, idx) => (
        <StyledTableRow key={idx}>
          <StyledTableCell width={250}>{t(row.label)}:</StyledTableCell>
          <StyledTableCell>{row.value}</StyledTableCell>
        </StyledTableRow>
      ))} */}
          {map(documentsByEntity(ATTACHED_DOCUMENTS), ({ docType, attachment }, idx) => (
            <StyledTableRow className="no-print" key={idx}>
              <StyledTableCell width={250}>{t(DOCS_TYPES_LABELS[docType])}:</StyledTableCell>
              <StyledTableCell>
                <FileUploader
                  onAttach={handleAttach}
                  attachment={attachment}
                  docType={docType as DocumentTypesEnums}
                  fileType={FileTypesEnums.APPLICATION}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}

          {isShowUploader &&
            map(documentsByEntity(UPLOADABLE_DOCUMENTS), ({ docType, attachment }, idx) => (
              <StyledTableRow className="no-print" key={idx}>
                <StyledTableCell width={250}>{t(DOCS_TYPES_LABELS[docType])}:</StyledTableCell>
                <StyledTableCell>
                  <FileUploader
                    onAttach={handleAttach}
                    attachment={attachment}
                    docType={docType as DocumentTypesEnums}
                    fileType={FileTypesEnums.APPLICATION}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}

          {isShowUploader &&
            map(documentsByEntity(GENERATABLE_DOCUMENTS), ({ docType, attachment }, idx) => (
              <StyledTableRow className="no-print" key={idx}>
                <StyledTableCell width={250}>{t(DOCS_TYPES_LABELS[docType])}:</StyledTableCell>
                <StyledTableCell>
                  <FileGenerator
                    onGenerate={handleGenerate}
                    attachment={attachment}
                    docType={docType as DocumentTypesEnums}
                    fileType={FileTypesEnums.APPLICATION}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}

          {underwriterStatus && (
            <StyledTableRow>
              <StyledTableCell width={250}>{t('Статус андеррайтера')}:</StyledTableCell>
              <StyledTableCell>
                {isChangeUnderwriterStatusVisible ? (
                  <SelectUnderwriterStatus
                    onChange={handleChangeUnderwriterStatus}
                    value={underwriterStatus}
                    size="small"
                    fullWidth
                  />
                ) : (
                  <StatusButtonUnderwriter status={underwriterStatus} />
                )}
              </StyledTableCell>
            </StyledTableRow>
          )}

          {loan && (
            <StyledTableRow>
              <StyledTableCell width={250}>{t('Кредит')}:</StyledTableCell>
              <StyledTableCell>
                <IconButton
                  component={Link}
                  to={dynamicRoute(ROUTES.CABINET.CREDITS.DETAILS.ROOT, {
                    id: get(loan, 'id', 0),
                  })}
                  size="small"
                  color="primary"
                >
                  {get(loan, 'id', 0)}
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </StyledTable>

      {data.data.is_show_copy_docs && (
        <Button
          variant="contained"
          color="info"
          disabled={isLoading}
          sx={{
            mt: 2,
            bgcolor: '#eee',
            color: '#303940',
            '&:hover': { bgcolor: '#ddd' },
          }}
          onClick={handleGetFiles}
        >
          Загрузить файлы из предыдущей заявки
        </Button>
      )}
    </div>
  );
};
